import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: "",
    role:"",
    user:'',
    userId:'',
    gameList:[],
  },
  getters: {
    getToken(state){
      return state.token ||localStorage.getItem("token") || ""
     },
     getRole(state){
      return state.role ||localStorage.getItem("role") || ""
     },
     getUser(state){
      return state.user ||localStorage.getItem("user") || ""
     },
     getUserId(state){
      return state.userId ||localStorage.getItem("userId") || ""
     },
     getGameList(state){
      return state.gameList || localStorage.getItem("gameList") || ""
     },
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload
      },
      delToken(state){
        state.token="",
        localStorage.removeItem("token");
      },
      setRole(state, payload) {
        state.role = payload
        },
        delRole(state){
          state.role="",
          localStorage.removeItem("role");
        },
        setUser(state, payload) {
          state.user = payload
          },
          delUser(state){
            state.user="",
            localStorage.removeItem("user");
          },
          setUserId(state, payload) {
            state.userId = payload
            },
            delUserId(state){
              state.userId="",
              localStorage.removeItem("userId");
            },
          setGameList(state, payload){
            state.gameList=payload
          },
          delGameList(state){
            state.gameList=[],
            localStorage.removeItem("gameList");
          },
  },
  actions: {
  },
  modules: {
  }
})
